<template>
  <nut-tabs v-model="tabmenu.value"
            class="top-tab">
    <template v-slot:titles>
      <div class="nut-tabs__titles-item"
           @click="getMenuCon(item.paneKey)"
           :class="{ active: tabmenu.value == item.paneKey }"
           :key="item.paneKey"
           v-for="item in tabmenu.menuList">
        <span class="nut-tabs__titles-item__text">{{ item.title }}</span>
      </div>
    </template>
    <!-- 成员管理 -->
    <nut-tabpane v-for="item in tabmenu.menuList"
                 :pane-key="item.paneKey"
                 :key="item.paneKey">
      <template v-if="tabmenu.value === '1'">
        <div class="whitebox">
          <nut-row class="top-text"
                   :gutter="10">
            <nut-col :span="17">
              <em class="text-red">企业权益：</em>您还可邀请{{
                memberNumberList.memberNumber
              }}位成员加入企业，现已添加
              <!-- <em class="text-blue">{{ userList.user.length }}</em
              >位， 成功添加的成员，可去应用市场下载SView企业版登录使用 -->
            </nut-col>
            <nut-col :span="7"
                     style="text-align:right">
              <nut-button type="primary"
                          class="blue-btn add-btn"
                          size="small"
                          @click="addMemberPop">添加成员
              </nut-button>
            </nut-col>
          </nut-row>
          <!--  x轴左右不可滑动，y轴上下 可滑动  -->
          <div :style="{'overflow-x' : 'hidden','overflow-y': 'scroll',height: (templateHeight-60) + 'px'}">
            <ul>
              <!-- 多个删除 -->
              <!-- <li
                v-for="(u, index) in userList.user"
                :key="index"
                class="mbox"
                @click="choseMember(index, u)"
                :class="{ active: liNums.indexOf(index) > -1 }"
              >
                <nut-checkbox
                  v-model="u.checkShow"
                  v-if="u.checkShow"
                ></nut-checkbox>
                <img :src="u.path" v-if="u.path !== ''" />
                <img src="https://sview.sv3d.cn/data/img/nophoto.jpg" v-else />
                <div class="user-info">
                  <h3>{{ u.nickName }}</h3>
                  <p>{{ u.userPhone }}</p>
                </div>
                <div class="del-box" v-if="delbtnShow">
                  <nut-button
                    type="primary"
                    icon="del2"
                    class="blue-btn"
                    @click.stop="delMember()"
                    >删除({{ liNums.length }})</nut-button
                  >
                </div>
              </li> -->
              <!-- 单个删除 -->
              <li v-for="(u, index) in userList.user"
                  :key="index"
                  class="mbox"
                  @click="choseMember(u, index)"
                  :class="{ active: currentIndex === index }">
                <nut-swipe :disabled="false"
                           style="width: 100%">
                  <nut-cell round-radius="0">
                    <img src="https://sview.sv3d.cn/data/img/nophoto.jpg"/>
                    <div class="user-info">
                      <h3>{{ u.nickName }}</h3>
                      <p>{{ u.userPhone }}</p>
                    </div>
                  </nut-cell>
                  <template #right>
                    <div
                        style="background-color: red;height: 80%;text-align: center;width: 80px;margin-top: 10px;margin-inside:10px;padding-top: 25px;"
                        @click="delDiaMember(index,u)">
                      <span style="color: white;">删除</span>
                    </div>
                    <!--                    <nut-button shape="square" style="height: 100%" type="danger"
                                @click="delDiaMember(index,u)">
                      删除
                    </nut-button>-->
                  </template>
                </nut-swipe>
              </li>
            </ul>
          </div>
          <!-- 添加好友弹窗 -->
          <nut-popup :title="title"
                     v-model:visible="addPopVisible"
                     position="bottom"
                     @ok="ok(newMember)">
            <nut-row class="inputlist"
                     :gutter="20">
              <nut-col :span="10" style="text-align: center"><em class="text-red">*</em>手机号</nut-col>
              <nut-col :span="10" style="text-align: center"><em class="text-red">*</em>姓名</nut-col>
            </nut-row>
            <nut-row :gutter="10"
                     style="overflow: hidden">
              <form :model="newMember">
                <div class="inputbox"
                     v-for="(item, index) in newMember"
                     :key="index">
                  <div class="leftwh">
                    <nut-input type="number"
                               v-model="newMember[index].phone"
                               placeholder="请输入手机号"
                               @clear="clear"/>
                  </div>
                  <div class="rtwh">
                    <nut-input v-model="newMember[index].name"
                               placeholder="请输入姓名"
                               @clear="clear"/>
                  </div>
                </div>
              </form>
            </nut-row>
            <div class="blank10"></div>
            <nut-row :gutter="10"
                     class="pop-bt">
              <div class="sku-operate-box">
                <nut-button class="sku-operate-box-dis pop-btn"
                            @click="resetForm(newMember)">重置
                </nut-button>
                <nut-button class="sku-operate-box-dis pop-btn"
                            type="info"
                            @click="ok(newMember)">添加
                </nut-button>
              </div>
            </nut-row>
            <div class="blank10"></div>
          </nut-popup>
        </div>
      </template>
      <template v-else-if="tabmenu.value === '2'">
        <!-- 优选商城 -->
        <div class="whitebox"
             :style="{overflow:'scroll',height: templateHeight + 'px'}">
          <ul class="infiniteUl pro-list"
              id="scroll"
              style="height: 100%">
            <nut-infiniteloading containerId="scroll"
                                 :use-window="false"
                                 :has-more="hasMore"
                                 @load-more="loadMore">
              <li v-for="(p, i) in proList"
                  :key="i"
                  @click="goProDetail(p.productId)">
                <img :src="p.pic"/>
                <div class="pro-info">
                  <h3 class="pro-name">{{ p.title }}</h3>
                  <p class="price">
                    <em class="text-red">¥{{ p.price }}</em><em>¥{{ p.vipPrice }}</em>
                    <nut-icon size="32"
                              :name="vipIcon"></nut-icon>
                  </p>
                  <p class="com-name">{{ p.gnums }}人购买</p>
                  <p class="com-name">{{ p.shopname }}</p>
                </div>
              </li>
              <template v-slot:loading>
                <div class="loading">
                  <span>加载中...</span>
                </div>
              </template>
              <template v-slot:unloadMore>
                <div class="unload-more">没有数据啦 ~~</div>
              </template>
            </nut-infiniteloading>
          </ul>
        </div>
      </template>
      <template v-else>
        <div class="graybox"
             :style="{overflow:'scroll',height: templateHeight + 'px'}">
          <div class="mybox">
            <h4>会员权益({{ ComboInfo.goodsName }})</h4>
            <div class="normal-box">
              <p>
                恭喜！您已经正式开通SView<em class="text-blue">{{
                  ComboInfo.goodsName
                }}</em>。
              </p>
              <p>
                有效期为：<em class="text-blue">{{
                  ComboInfo.expiratioTtime
                }}</em>
              </p>
              <p>在此期间，您可解锁以下功能：</p>
              <ul class="textli">
                <li>
                  <nut-icon name="fabulous"></nut-icon>
                  邀请成员、管理成员；
                </li>
                <li>
                  <nut-icon name="fabulous"></nut-icon>
                  创建项目、实现协同管理；
                </li>
                <li>
                  <nut-icon name="fabulous"></nut-icon>
                  可享受{{
                    ComboInfo.userSpaceSize
                  }}云存储图纸空间；
                </li>
                <li>
                  图纸安全分享、查询浏览记录等，并额外获得图纸数据测量的增值功能。
                </li>
              </ul>
              <p>欢迎您的加入，SView与您携手共创佳绩！</p>
            </div>
            <h4>登记信息</h4>
            <div class="normal-box">
              <p>企业名称：{{ etpInfo.name }}</p>
              <p>联系电话：{{ etpInfo.phone }}</p>
            </div>
            <h4>邀请统计</h4>
            <div class="normal-box"
                 @click="getInviteeUser">
              <p>已成功邀请人数：
                <em class="text-blue">
                  {{ inviteeCount }}
                </em>
              </p>
            </div>
            <h4>其它功能</h4>
            <div class="normal-box">
              <nut-row :gutter="6">
                <nut-col :span="5">
                  <nut-icon class-prefix="nut-icon"
                            name="order"
                            size="24px"
                            color="#64b578"
                            @click="orderClick"/>
                  <p style="margin-left:-16px">我的订单</p>
                </nut-col>
                <nut-col :span="5">
                  <nut-icon class-prefix="nut-icon"
                            name="share"
                            size="24px"
                            color="#64b578"
                            @click="share()"/>
                  <p style="margin-left:-5px">分享</p>
                </nut-col>
              </nut-row>
            </div>
          </div>
        </div>
      </template>
    </nut-tabpane>
  </nut-tabs>
  <!-- 邀请统计人员信息 弹出层 -->
  <nut-popup :style="{ padding: '50px', height:'30%' }"
             round
             position="bottom"
             v-model:visible="inviteeModel">
    <ul>
      <li v-for="(u, index) in inviteeUser"
          :key="index"
          class="mbox">
        <img src="https://sview.sv3d.cn/data/img/nophoto.jpg"/>
        <div class="user-info"
             style="width: 200px">
          <h3>
            {{ u.etpName }}
          </h3>
          <p>
            {{ u.phone }}
          </p>
          <p>
            {{ u.createTime }}
          </p>
        </div>
      </li>
    </ul>
  </nut-popup>
</template>
<script>
import {getCurrentInstance, onMounted, reactive, ref, toRefs} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {Notify} from '@nutui/nutui';
import useClipboard from 'vue-clipboard3';
import NativeShare from 'nativeshare';
import {getCookie} from '@/utils/cookie'

export default {
  name: 'member',
  setup() {
    const router = useRouter();
    const route = useRoute();
    let Base64 = require('js-base64').Base64;
    const {proxy} = getCurrentInstance();
    //复制
    const {toClipboard} = useClipboard();
    //高度
    const templateHeight = ref('700');
    //分享
    const nativeShare = new NativeShare();
    //邀请人数
    const inviteeCount = ref('正在加载中...');
    const inviteeCode = ref('');
    const innerId = route.query.innerId !== undefined ? Base64.decode(route.query.innerId) : '';
    //项目ID
    let projectId = '';
    //默认项目
    let isAuthenticExist = false;
    // 套餐信息
    const ComboInfo = reactive({});
    // 选中成员数组
    const currentMember = ref();
    const liNums = reactive([]);
    const version = reactive();
    const delbtnShow = ref(false);
    const currentIndex = ref(-2);
    const addPopVisible = ref(false);
    const newMember = reactive([
      {
        phone: '',
        name: '',
      },
      {
        phone: '',
        name: '',
      },
      {
        phone: '',
        name: '',
      },
      {
        phone: '',
        name: '',
      },
    ]);
    //企业基本信息
    const etpInfo = reactive({
      name: '',
      phone: '',
    });
    const title = '新增成员';
    const content = '内容';

    const tabmenu = reactive({
      value: '1',
      menuList: [
        {title: '成员管理', paneKey: '1'},
        {title: '优选商城', paneKey: '2'},
        {title: '我的', paneKey: '3'},
      ],
    });
    const userList = reactive({
      current: 0,
      user: [],
    });
    const data = reactive({
      // 商城产品
      proList: [],
      //邀请用户企业信息
      inviteeUser: [],
    });
    const addMemberPop = () => {
      addPopVisible.value = true;
    };
    // 套餐信息查询
    const getcomboInfo = () => {
      const etpParams = new URLSearchParams();
      etpParams.append('innerId', innerId);
      proxy.$axios.post('https://work.sview3d.com/phone/getUserComboInfo ', etpParams).then(res => {
        console.log('res.expiratioTtime>>>' + JSON.stringify(res));
        ComboInfo.expiratioTtime = res.data.expiratioTtime;
        ComboInfo.goodsName = res.data.goodsName;
        //  套餐状态
        if (res.data.goodsName == '基础版') {
          ComboInfo.userSpaceSize = '10G';
        } else if (res.data.goodsName == '标准版') {
          ComboInfo.userSpaceSize = '1T';
        } else if (res.data.goodsName == '协作版') {
          ComboInfo.userSpaceSize = '10T';
        } else {
          ComboInfo.userSpaceSize = '10T';
        }
      });
    };
    // 企业信息查询
    const getCompanyInfo = () => {
      const etpParams = new URLSearchParams();
      etpParams.append('innerId', innerId);
      proxy.$axios
          .post(
              'https://work.sview3d.com/liftcloud/usercenter/enterprises/action/getEnterprisesData.jsp ',
              etpParams
          )
          .then(res => {
            etpInfo.name = res.data.result.name;
            etpInfo.phone = res.data.result.companyphone;
          });
    };
    // 多个成员选择
    // const choseMember = (index, u) => {
    //   let arrIndex = liNums.indexOf(index)
    //   if (arrIndex > -1) {
    //     // 第一次点击
    //     liNums.splice(arrIndex, 1)
    //     currentMember.splice(index, 1)
    //   } else {
    //     liNums.push(index)
    //     currentMember.push(u)
    //   }
    //   u.checkShow = !u.checkShow
    //   console.log('liNums.length>>>>' + liNums.length)
    //   if (liNums.length > 0) {
    //     delbtnShow.value = true
    //   } else {
    //     delbtnShow.value = false
    //   }
    // }
    // 单个选择成员
    const choseMember = (u, index) => {
      if (currentIndex.value == index) {
        currentIndex.value = -1;
      } else {
        currentIndex.value = index;
        u.checkShow = true;
        delbtnShow.value = true;
        currentMember.value = u;
      }
    };
    const getMenuCon = panekey => {
      tabmenu.value = panekey;
      console.log('paneKey>>>' + panekey);
      if (panekey == '3') {
        getCompanyInfo();
        getcomboInfo();
      }
    };
    const clear = value => {
      console.log('clear:', value);
    };
    // 没有效果 下拉加载 懒加载代码
    const hasMore = ref(true);
    const loadMore = done => {
      setTimeout(() => {
        /*const curLen = data.proList.length
for (let i = curLen; i < curLen + 10; i++) {
  data.proList.push(`${i}`)
}
if (data.proList.length > 30) hasMore.value = false*/
        done();
      }, 500);
    };
    // 下拉加载数据初始化，现调用getProList()方法
    /*const init = () => {
  for (let i = 0; i < 10; i++) {
    data.proList.push(`${i}`)
  }
}*/
    // 添加好友重置按钮
    const resetForm = newMember => {
      for (let i = 0; i < newMember.length; i++) {
        console.log(newMember[i].phone);
        newMember[i].phone = '';
        newMember[i].name = '';
      }
    };
    // 新增成员弹窗回调
    const ok = async newMember => {
      addPopVisible.value = false;
      const phoneRegular = /^1[3|4|5|7|6|8|9]\d{9}$/;
      for (let i = 0; i < newMember.length; i++) {
        if (newMember[i].phone !== '' && newMember[i].name !== '') {
          console.log('newMember[i].phone>>>' + newMember[i].phone + newMember[i].name);
          if (!phoneRegular.exec(newMember[i].phone)) {
            Notify.warn('请输入正确的手机号');
            return false;
          }
          const etpParams = new URLSearchParams();
          etpParams.append('phone_name', newMember[i].phone + '-' + newMember[i].name);
          etpParams.append('createuserid', innerId);
          etpParams.append('etpid', innerId);
          await proxy.$axios
              .post(
                  'https://work.sview3d.com/liftcloud/usercenter/enterpriseuser/action/addEtpUserAction.jsp',
                  etpParams
              )
              .then(res => {
                if (res.data.error == -1) {
                  Notify.danger(res.data.msg);
                }
                console.log(res);
                getMember();
                queryMemberNumber();
              });
          if (newMember[i + 1].phone == '') {
            break;
          }
        } else {
          console.log(i);
          Notify.warn('手机号、姓名不能为空');
          break;
        }
      }
    };
    // 商城产品跳转
    const goProDetail = productId => {
      //前端传json需要JSON.stringify转成json字符串
      if (fromtype.value != 0 && fromtype.value != 1) {
        router.push({path: 'prodetail', query: {productId: productId, userId: route.query.innerId}});
      } else {
        router.replace({path: 'prodetail', query: {productId: productId, userId: route.query.innerId}});
      }
      //window.location.href=p.intenturl;
    };
    const pagination = reactive({
          // 当前页码
          current: 1,
          // 每页的条数
          pageSize: 50,
          // 可切换的条数
          pageSizeOptions: ['50'],
          // 数据总数（目前并不知道真实的总数，所以先填写0，在后台查出来后再赋值）
          total: 0,
        }),
        // 成员列表拉取
        getMember = () => {
          const etpParams = new URLSearchParams();
          etpParams.append('pageSize', pagination.pageSize);
          etpParams.append('currentPage', 1);
          etpParams.append('etpId', innerId);
          proxy.$axios
              .post(
                  'https://work.sview3d.com/liftcloud/usercenter/enterpriseuser/action/getEtpUserData.jsp',
                  etpParams
              )
              .then(res => {
                userList.user = res.data.result;
              });
        };
    // 循环删除
    // const delMember = () => {
    //   for (let index = 0; index < currentMember.length; index++) {
    //     delDiaMember(index, currentMember)
    //   }
    //   getMember()
    // }
    // 单个删除成员
    const delDiaMember = (index, user) => {
      if (user.userId === user.enterprisesId) {
        Notify.warn('你不能删除你自己');
      } else {
        const etpParams = new URLSearchParams();
        etpParams.append('etpId', innerId);
        etpParams.append('userId', user.userId);
        etpParams.append('etp_vip_id', user.etp_vip_id);
        etpParams.append('etp_user_vip_id', user.etp_user_vip_id);
        proxy.$axios
            .post(
                'https://work.sview3d.com/liftcloud/usercenter/enterpriseuser/action/delEtpVipUser.jsp',
                etpParams
            )
            .then(res => {
              currentIndex.value = -2;
              getMember();
              queryMemberNumber();
              //删除默认项目成员
              delProjectUser(user.bimInviteId, user.userId);
            });
      }
    };

    //删除默认项目成员
    const delProjectUser = (bimInviteId, inviteInnerId) => {
      if (isAuthenticExist) {
        const etpParams = new URLSearchParams();
        etpParams.append('innerId', bimInviteId);
        etpParams.append('inviteInnerId', inviteInnerId);
        etpParams.append('projectInnerId', projectId);
        proxy.$axios
            .post(
                'https://work.sview3d.com/projectManager/project/member/action/memberDelete.jsp',
                etpParams
            )
            .then(res => {
              if (res.data.reback === true || res.data.reback === "true") {
                deleteMemberFromGroup(inviteInnerId);
              }
            });
      }
    }
    //移除项目群组
    const deleteMemberFromGroup = (inviteInnerId) => {
      const etpParams = new URLSearchParams();
      etpParams.append('userId', inviteInnerId);
      etpParams.append('projectId', projectId);
      proxy.$axios
          .post(
              'https://sview.sv3d.cn/v5/service/user/removeGroupAcount',
              etpParams
          ).then();
    }
    //可添加成员数
    const memberNumberList = reactive({
      memberNumber: '0',
    });
    //获取可添加成员数
    const queryMemberNumber = () => {
      const etpParams = new URLSearchParams();
      etpParams.append('etpId', innerId);
      proxy.$axios
          .post(
              'https://work.sview3d.com/liftcloud/usercenter/enterpriseuser/action/getEtpVipMemberInfo.jsp',
              etpParams
          )
          .then(res => {
            let usedVipMemberNumTotal = res.data.usedVipMemberNumTotal;
            let vipMemberNumTotal = res.data.vipMemberNumTotal;
            memberNumberList.memberNumber = vipMemberNumTotal - usedVipMemberNumTotal + '';
            companyInformation();
          });
    };
    /*查询企业信息*/
    const companyInformation = () => {
      proxy.$axios.get('https://work.sview3d.com/company/information?userId=' + innerId + '&etpId=' + innerId)
          .then(function (response) {
            let goodsId = response.data.goodsId;
            //40-1 专业版，41-1 专业版试用 无限人数
            if ('40-1' === goodsId || '41-1' === goodsId) {
              memberNumberList.memberNumber = '无限';
            }
            isAuthenticExist = response.data.isAuthenticExist;
            if (isAuthenticExist) {
              projectId = response.data.projectInnerId;
            }
          });
    }
    // 判断是否是来自app 0 安卓 1 苹果
    const fromtype = ref()
    if (getCookie('fromtype')) {
      fromtype.value = getCookie('fromtype')
    }
    console.log('fromtype: ' + fromtype.value)
    //禁止返回上一页
    const noReturn = () => {
      if (fromtype.value != 0 && fromtype.value != 1) {
        history.pushState(null, null, document.URL);
        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL);
        });
      }
    };

    const share = () => {
      let url = 'https://work.sview3d.com/phone/invitaioCode';
      const params = new URLSearchParams();
      params.append('userId', innerId);
      proxy.$axios
          .post(url, params)
          .then(res => {
            if (res.data.success == 'true') {
              const invitationCode = res.data.invitionCode;
              const shareUrl = 'https://work.sview3d.com/phoneWeb/invite?invitationCode=' + invitationCode;
              nativeShare.setShareData({
                icon: 'https://work.sview3d.com/phoneWeb/img/enterprise-logo.a49cf6d4.jpg',
                link: shareUrl,
                title: '随心所阅，畅览3D',
                desc: '高效查看40种CAD，多终端登录协同互动，整合人员/项目/图纸管理',
                from: '@fa-ge',
              });
              proxy.$dialog({
                title: '分享链接',
                content: shareUrl,
                okText: '复制',
                cancelText: '返回',
                onOk: function () {
                  //复制链接
                  toClipboard(shareUrl);
                  try {
                    nativeShare.call();
                  } catch (err) {
                    //不支持浏览器原生分享
                  }
                  Notify.success('复制成功，请您手动分享');
                },
              });
            } else {
              proxy.$dialog({
                title: '错误提示',
                content: res.data.msg,
                noCancelBtn: true,
              });
            }
          })
          .catch();
    };
    /*跳转到订单页面*/
    const orderClick = () => {
      if (fromtype.value != 0 && fromtype.value != 1) {
        router.push({path: 'order', query: {innerId: route.query.innerId}});
      } else {
        router.replace({path: 'order', query: {innerId: route.query.innerId}});
      }
    };

    const getProList = () => {
      const url =
          'https://www.sview3d.com/mobile/product/productCatergory/productList.jsp?product={"userid":"' +
          innerId +
          '","etpid":"","request" :[{"sortway":"","sorttype":"","ctgid":"","currentpage":"1","pagesize":"50"}]}&type=3';
      proxy.$axios
          .get(encodeURI(url))
          .then(function (res) {
            const arr = [];
            const products = res.data.products;
            for (let i = 0; i < products.length; i++) {
              const json = {};
              //商品名称
              json.title = products[i].classname;
              json.pic = products[i].image;
              //购买人数
              json.gnums = products[i].saleCount;
              //售价
              json.price = products[i].price;
              //vip价格
              json.vipPrice = products[i].vipPrice;
              //企业名称
              json.shopname = products[i].company;
              //原价
              json.originalPrice = products[i].originalPrice;
              //"http://localhost:8081/mobile/product/productInfoDetail.jsp?innerId=0bc4a2d82ed84d4794be2d16ded342e3&userid=1672a93d-447a-403b-80d3-4a998736f351"
              //intenturl是商品详情页，没有购买
              json.intenturl = products[i].intenturl;
              //innerid是商品id,userid是当前登录用户id
              json.productId = products[i].innerid;
              arr.push(json);
            }
            data.proList = arr;
          })
          .catch();
    };
    //获取邀请次数
    const getInviteeCount = () => {
      let url = 'https://www.sview3d.com/personalInviteCount?innerId=' + innerId;
      proxy.$axios
          .get(url)
          .then(function (params) {
            if (true === params.data.success) {
              //有效邀请次数（即被邀请人已企业登记）
              inviteeCount.value = params.data.countNum;
              //邀请码
              inviteeCode.value = params.data.inviteCode;
            } else {
              inviteeCount.value = '0';
            }
          })
          .catch(function (error) {
            console.log(error);
            inviteeCount.value = '0';
          });
    };
    //邀请企业用户弹出层
    const inviteeModel = ref(false);
    //获取邀请用户企业信息
    const getInviteeUser = () => {
      if ('0' === inviteeCount.value
          || '正在加载中...' === inviteeCount.value
          || '' === inviteeCount.value) {
        return;
      }
      let url = 'https://www.sview3d.com/inviteeInfo?inviteCode=' + inviteeCode.value;
      proxy.$axios
          .get(url)
          .then(function (params) {
            data.inviteeUser = params.data.rows;
            inviteeModel.value = true;
          })
          .catch(function (error) {
            console.log(error);
          });
    };

    //根据游览器窗口大小加载高度
    const getWindowsHeight = () => {
      const windowsHeight = document.documentElement.clientHeight;
      templateHeight.value = windowsHeight - 50;
    };

    onMounted(() => {
      getWindowsHeight();
      if (innerId == null || innerId === '') {
        Notify.danger('访问异常,请重新登录！');
        router.push('/phoneWeb/');
        return false;
      }
      getInviteeCount();
      getMember();
      //init();
      queryMemberNumber();
      noReturn();
      getProList();
      companyInformation();
      //判断打开那个标签
      if (undefined !== route.params.tabMenu && null != route.params.tabMenu && '' !== route.params.tabMenu) {
        tabmenu.value = route.params.tabMenu;
        if (tabmenu.value == '3') {
          getCompanyInfo()
          getcomboInfo()
        }
      }
    });
    return {
      tabmenu,
      userList,
      liNums,
      currentMember,
      version,
      pagination,
      ComboInfo,
      loadMore,
      hasMore,
      ...toRefs(data),
      vipIcon: require('../assets/img/vip.png'),
      currentIndex,
      delbtnShow,
      getMenuCon,
      addMemberPop,
      choseMember,
      getMember,
      getcomboInfo,
      ok,
      clear,
      goProDetail,
      getCompanyInfo,
      addPopVisible,
      resetForm,
      title,
      content,
      newMember,
      delDiaMember,
      queryMemberNumber,
      memberNumberList,
      etpInfo,
      noReturn,
      share,
      getProList,
      inviteeCount,
      getInviteeUser,
      inviteeModel,
      inviteeCode,
      orderClick,
      templateHeight,
      getWindowsHeight,
    };
  },
};
</script>
<style scoped>
</style>
